import { ProjectConfig } from 'Global';
import { ApiRequest } from 'GlobalFunctions';
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CategoriesSlider(props: any) {
	const navigate = useNavigate();

	const handleSelectCategory = (id: string, name: string) => {
		console.log(id, name);
	};

	const [data, setData] = useState<any>([]);
	const loadData = () => {
		const state_id = localStorage.getItem('state');

		ApiRequest({
			url: `/v2/estados/${state_id}/dinamicas`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			setResponse: (response: any) => {
				setData(response);
			},
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	return (
		<div className="category_carousel">
			<h2>Categorías</h2>
			<CarouselProvider
				naturalSlideWidth={100}
				naturalSlideHeight={100}
				totalSlides={data.length}
				visibleSlides={4}
			>
				<Slider className="slider_container">
					{data.map((category: any, index: any) => (
						<Slide
							index={index}
							key={index}
							onClick={() => {
								navigate(`/products/category/${category.id}`);
							}}
						>
							<div className="icon_container">
								<img
									className="category_icon"
									style={{ backgroundColor: category.color }}
									src={category.img}
									alt={category.name}
								/>
							</div>
						</Slide>
					))}
				</Slider>
			</CarouselProvider>
		</div>
	);
}
