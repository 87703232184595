import { useLocation } from 'react-router-dom';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import SdkclaroContext from 'Context/SdkclaroContext';
import { useContext, useEffect, useState } from 'react';

export default function ProductDetail(props: any) {
	const { sdkclaroInstance, idOpen, profileInformation } =
		useContext(SdkclaroContext);
	const location = useLocation();
	const { item } = location.state || {};

	// Logo handling
	const [logo, setLogo] = useState<string>('');

	const setLogo64 = async () => {
		const logo64 = await fetch('/logoBase64.txt');
		const logo64Text = await logo64.text();
		setLogo(logo64Text);
	};

	useEffect(() => {
		setLogo64();
	}, []);

	// Default and item-based data handling
	const data = item
		? {
				img: item.productos[0].img,
				nombre: item.productos[0].nombre,
				precio: item.productos[0].precio,
				descripcion: item.promocion,
		  }
		: {
				img: 'https://placehold.co/300x200',
				nombre: 'Test',
				precio: 100,
				descripcion:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc ultricies ultricies. Nullam nec purus nec nunc ultricies ultricies. Nullam nec purus nec nunc',
		  };

	const encodedNombre = encodeURIComponent(data.nombre);
	const encodedDescripcion = encodeURIComponent(data.descripcion);

	// UUID generator
	const uuid = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				const r = (Math.random() * 16) | 0;
				const v = c === 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	};

	const key = uuid();

	// Form submission handler
	const handleSubmit = async () => {
		localStorage.setItem(
			'profileInformation',
			JSON.stringify(profileInformation)
		);

		const productState = {
			cardNumber: item.productos[0].id,
			idCom: '0074',
			idGrp: '0002',
			checkDigit: '1',
			amount: item.productos[0].precio,
			appId: idOpen,
		};

		// Set state in SDK
		await sdkclaroInstance.setState(
			key,
			JSON.stringify(productState),
			(result: any) => {
				console.log('stateResult: ', result);
			},
			(error: any) => {
				console.log('error: ', error);
			}
		);

		// Process transaction payment
		await sdkclaroInstance.transactionPayment(
			{
				amount: item.productos[0].precio,
				category: 'MA',
				claroUserId: profileInformation.username,
				concept: item.productos[0].nombre,
				description: item.promocion,
				feeAmount: 0,
				logo: logo,
				merchantId: '0000000093e5f4b10193e62051ec0001',
				operationId: key,
				payProcessor: { id: 1, name: 'N2', showCVV: false },
				reference: '123456789',
				totalCommission: 0,
			},
			(result: any) => {
				console.log('transactionPayment: ', JSON.stringify(result));
			},
			(error: any) => {
				console.log(error);
			}
		);
	};

	return (
		<div className="content_wrapper">
			<div className="product_detail_container">
				<div className="product_detail">
					<img src={data.img} alt="banner promocional" />

					<div className="product_detail_header">
						<p className="product_card_name">
							{decodeURIComponent(encodedNombre)}
						</p>
						<p className="product_card_price">${data.precio}</p>
					</div>

					<div className="product_detail_content">
						<h2>Descripción</h2>
						<div className="product_description">
							{decodeURIComponent(encodedDescripcion)}
						</div>
					</div>
				</div>

				<div className="product_buttons">
					<div
						className="product_button"
						onClick={() => {
							handleSubmit();
						}}
					>
						<div>
							<LocalMallOutlinedIcon />
							<span>Comprar</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
