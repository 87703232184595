import { Button } from '@mui/material';
import ic_states from '../../assets/icons/ic_states.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState, useContext } from 'react';
import SdkclaroContext from 'Context/SdkclaroContext';

export default function States(props: any) {
	const { sdkclaroInstance, idOpen, profileInformation } =
		useContext(SdkclaroContext);
	const [data, setData] = useState<any>([]);
	const navigate = useNavigate();

	const loadData = () => {
		ApiRequest({
			url: '/v2/estados',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			setResponse: (response: any) => {
				response.forEach((state: any) => {
					state.url = state.url.replace(/-/g, ' ');
				});

				const sortedData = response.sort((a: any, b: any) => {
					return a.url.localeCompare(b.url);
				});

				/*IF STATE EXISTS, redirect to /state/:id
                =============================================*/
				if (typeof profileInformation.state === 'string') {
					console.log(
						'State found in profileInformation: ',
						profileInformation.state
					);
					const state = sortedData.find(
						(state: any) =>
							state.url.toLowerCase() === profileInformation.state.toLowerCase()
					);
					if (state) {
						navigate(`/state/${state.id}`, { replace: true });
					} else {
						console.log(
							`State ${profileInformation.state} not found in states`
						);
					}
				} else {
					console.log('No valid state found in profileInformation');
				}

				setData(sortedData);
			},
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	return (
		<div className="content">
			<div id="state_selector" className="box">
				<h1 className="iconTitle">
					<img src={ic_states} alt="icon" />
					Selecciona un estado:
				</h1>

				<div className="scrollView">
					{/* map states */}
					{data.map((state: any, index: any) => (
						<Button
							component={Link}
							to={`/state/${state.id}`}
							onClick={() => {
								localStorage.setItem('state', state.id);
							}}
							key={index}
							className="stateButton"
							variant="text"
							fullWidth
							style={{
								color: '#7a7a7a',
								textTransform: 'uppercase',
								padding: '10px 0',
								borderBottom: '1px solid #cdc8c8',
							}}
						>
							{state.url}
						</Button>
					))}
				</div>
			</div>
		</div>
	);
}
